@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Noto Sans KR", system-ui;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px !important;
  }
}

/* modal css */
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.modal-body {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal-body .content {
  background: white;
  padding: 1rem;
  /* width: 400px; */
  border-radius: 14px;
  height: auto;
  min-height: 60vh;
}

.bg-animation {
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);

  transition: transform 0.3s;
}
